var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        [
          _c(
            "a-table",
            {
              attrs: {
                "data-source": _vm.dataSource,
                "row-key": function(r) {
                  return r.id
                },
                pagination: false,
                scroll: { y: 320 }
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function(currentPageData) {
                    return [
                      _c("span", {}, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("lbl_total_qty_available", {
                                data: _vm.calcTotal(currentPageData)
                              })
                            ) +
                            " "
                        )
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "a-table-column",
                {
                  key: "no",
                  attrs: { width: "70px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record, index) {
                        return _c("span", {}, [_vm._v(_vm._s(index + 1))])
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_number_short")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "batchNumber",
                  attrs: { "data-index": "batchNumber" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text) {
                        return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "warehouseLocationName",
                  attrs: { "data-index": "warehouseLocationName" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text) {
                        return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_rack")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "available",
                  attrs: { "data-index": "available" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text) {
                        return _c("span", {}, [
                          _vm._v(_vm._s(_vm._f("toDecimalQty")(text)))
                        ])
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_qty_available")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "packDate",
                  attrs: { "data-index": "packDate" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text) {
                        return _c("span", {}, [
                          _vm._v(_vm._s(_vm._f("date")(text)))
                        ])
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_pack_date")))
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }