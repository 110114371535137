
























































import { formatDecimalQty } from "@/helpers/common";
import { ResponseInventoryLineBatch } from "@/models/interface-v2/inventory-line-batch.interface";
import Vue from "vue";
export default Vue.extend({
  name: "SalesOrderProductLinesBN",
  props: {
    dataSource: {
      type: Array as () => ResponseInventoryLineBatch[],
      default: undefined,
    },
  },
  computed: {
  },
  methods: {
    calcTotal(data: ResponseInventoryLineBatch[]): string | number {
      const total = data.reduce((a, b) => a + b.available, 0);
      return formatDecimalQty(total);
    },
  }
});
